import BaseService from "./BaseService";

export default class HomeService extends BaseService {

    getDados(){
        return this.requester.get(
            `${this.url}/api/home`
        );
    }

    getJobs(){
        return  this.requester.get(
            `${this.url}/api/jobs`
        );
    }

}
