<template>
    <div>
        <Header/>
        <div>
            <HomePage :home_texto="dados_home_texto"/>
<!--            <QuemSomos/>-->
            <Parceiros/>
            <Informacoes :texto_sobre="dados_texto_sobre"/>
            <Servicos :texto_servicos="dados_servicos" :blocos_servicos="blocos_servicos"/>
            <Trabalhos :texto_jobs="dados_jobs" :jobs="jobs"/>
            <CallToAction ref="contatos"/>
            <Footer/>
            <Cookie/>
        </div>
    </div>
</template>

<script>
import {scroller} from "vue-scrollto/src/scrollTo";
import HomeService from "../services/HomeService";
import _ from 'lodash';

export default {
    components: {
        Header: () => import("../components/Header"),
        HomePage: () => import("../components/HomePage"),
        // QuemSomos: () => import("../components/QuemSomos"),
        Parceiros: () => import("../components/Parceiros"),
        Informacoes: () => import("../components/Informacoes"),
        Servicos: () => import("../components/Servicos"),
        Trabalhos: () => import("../components/Trabalhos"),
        CallToAction: () => import("../components/CallToAction"),
        Footer: () => import("../components/Footer"),
        Cookie: () => import("../components/Cookie"),
    },
    data() {
        return {
            dados: null,
            dados_home_texto: {
                title: 'ENC HOJE',
                description: 'Sub titulo'
            },
            dados_texto_sobre: {
                title: 'SAIBA COMO...',
                description: 'LOREM IPSUM'
            },
            dados_servicos: {
                title: 'Lorem ipsum in dolor',
                description: '<p >Lorem ipsum</p>'
            },
            dados_jobs:{
                title: 'Lorem ipsum in dolor',
                description: 'Lorem ipsum'
            },
            blocos_servicos:[],
            jobs: []
        }
    },
    methods: {
        getDadosHome() {

        }
    },
    created() {
        this.homeService = new HomeService()
    },
    mounted() {

        
        this.homeService.getDados().then(({data}) => {
            let home_texto = {}
            _.find(data.data, function (obj) {
                if (obj.type_id == 3) {
                    home_texto = {title: obj.title, description: obj.description, link: obj.link}
                }
            });

            let texto_sobre = {}
            _.find(data.data, function (obj) {
                if (obj.type_id == 5) {
                    texto_sobre = {title: obj.title, description: obj.description, link: obj.link}
                }
            });

            let texto_servico = {}
            _.find(data.data, function (obj) {
                if (obj.type_id == 6) {
                    texto_servico = {title: obj.title, description: obj.description}
                }
            });

            let blocos_servicos = []
            _.find(data.data, function (obj) {
                if (obj.type_id == 7) {
                    blocos_servicos.push({icon: obj.icon, title: obj.title})
                }
            })

            let texto_jobs = {}
            _.find(data.data, function (obj) {
                if (obj.type_id == 8) {
                    texto_jobs = {title: obj.title, description: obj.description}
                }
            });


            this.dados_home_texto = home_texto
            this.dados_texto_sobre = texto_sobre
            this.dados_servicos = texto_servico
            this.dados_jobs = texto_jobs
            this.blocos_servicos = blocos_servicos
        });

        this.homeService.getJobs().then((response) => {
            this.jobs = response.data.data;
        })

        setTimeout(()=>{
                let params = this.$route.path.replace('/','');
                const firstScrollTo = scroller();
                if (params) {
                    firstScrollTo("#" + params, 800, {offset : -110});
                }

            }, 500)
    }

}
</script>

